/* ================== SECTIONS =================== */
/* ================== header main ==================  */

.App{
  background-color: #3167eb;
}
.header-top-light .nav-link {
  color: #969696;
}
.header-top-light .nav-link:hover {
  color: #212529;
}

.header-main {
  position: relative;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.brand-wrap .logo, .navbar-brand .logo {
  margin-right: 7px;
  max-height: 42px;
  display: inline-block;
}

.navbar-main .nav-link {
  font-weight: 500;
  /* color: #1c1c1c; */
  color: #fff !important;
}

/* @media(min-width: 1024px){
  .navbar-main .nav-link {
    color: #fff !important;
  }
} */

.navbar-main .nav-link:hover .nav-link {
  display: inline-block;
  position: relative;
  color: #ff8940d8 !important;
  /* border-bottom: #ff8940d8 3px solid !important; */
}

  .navbar-main .nav-link:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #fa9e6d !important;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out !important;
  }
   
  .navbar-main .nav-link:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }


.widget-header {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}
.widget-header a {
  color: #343a40;
}
.widget-header a:hover {
  /* color: #3167eb; */
  color: #fa9e6d !important;
  border-color: #fa9e6d !important;
}
.widget-header i {
  color: #969696;
}
.widget-header form {
  min-width: 280px;
}
.widget-header .title {
  display: block;
}
.widget-header .notify {
  position: absolute;
  top: -3px;
  right: -10px;
}
.widget-header:hover i {
  /* color: #3167eb; */
  color: #fa9e6d;
}

.mr-3{
  margin-right: 1rem !important;
}

.icontext{
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
}

@media(min-width:768px){
  .float-md-right{
    float: right !important;
  }
}

@media(max-width: 1024px){
  .col-lg-2.col-6, .col-lg-6.col-12.col-sm-12, .col-lg-6.col-12.col-sm-12, .col-lg-4.col-sm-6.col-12 {
    margin-top: 10px;
  }

  .dropdown-menu a{
    color: #000 !important;
  }

  /* .navbar-nav .navbar-collapse.collapse.show{
    text-align: left;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 18vw;
    left: 0;
    z-index: 9999;
    overflow-y: auto;
    background: #fff;
    transition: .2s ease-in-out;
  } */
}