.filter-group {
  border-bottom: 1px solid #e4e4e4;
}
.filter-group .card-header {
  border-bottom: 0;
}
.filter-group:last-child {
  border-bottom: 0;
}

button{
  border: none;
  background: rgba(0,0,0,.03); 
  width: 100%;
}

.list-menu li{
  list-style-type: none;
}

.list-menu li a {
  color: #000;
}

.list-menu li a:active {
  color: #fa9e6d;
}