*{
  font-family: 'Cabin', sans-serif;
}

.about span{
  font-weight: bold;
  --bs-text-opacity: 1;
  color: #023976;
}

.about .center{
  display: flex;
  justify-content: space-around;
}

.about .cards-wrapper{
  align-items: end;
}

@media(max-width: 768px ){
  .about .cards-wrapper{
    display: flex;
    flex-direction: column;
  }
}

.about .card {
  color: #fff;
  border-top-left-radius: 10px !important;
  height: min-content;
}

.about .card .card-title{
  text-transform: uppercase;
}

.about .activities {
  margin: auto;
  margin-top: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about .activities .card {
  color: #000;
}

.about .counter .card{
  border-radius: 15px;
}

.about .counter .card-title{
  color: #023976;
  font-weight: 600;
}

.about .counter .card-subtitle{
  color: #000;
}

.about .counter span, .about .counter p{
  color: #000;
}

.counter{
  background-color: #f8fcf9;
  margin: 10px;
  padding: 20px;
}

.first{
  position: relative;
  left: -25px;
  padding: 3px;
  border: 3px solid #83342c;
  border-radius: 5px;
}

.second{
  position: relative;
  top: -25px;
  left: 40px;
  padding: 3px;
  border: 3px solid #83342c;
  border-radius: 5px;
}

/* mat */

.fmat {
  border-top: 3px solid #83342c !important;
}

.fmat span p, .fmat span{
  color: #83342c;
  font-weight: 700;
}

/* res */

.fres {
  border-top: 3px solid #077dbc !important;
}

.fres span p, .fres span{
  color: #077dbc;
} 

/* aid */

.faid{
  border-top: 3px solid #3fa0cc !important;
}

.faid span p, .faid span{
  color: #3fa0cc ;
}

/* pro */

.fpro{
  border-top: 3px solid #231f20 !important;
}

.fpro span{
  color: #231f20;
}

/* rel */

.frel{
  border-top: 3px solid #f9e370 !important;
}

.frel span{
  color: #f9e370;
}

.fvdf{
  border-top: 3px solid #fa9e6d !important;
}

.logo-container{
  text-align: center;
}

.logo{
  max-width: 400px;
}

a:active, a:hover{
  color: #ff8940d8 !important;
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&family=Oswald&display=swap');

.carousel-title{
  text-transform: uppercase;
  font-size: 3em; 
  font-family: 'Open Sans', sans-serif;
  color: #fff!important;
  font-weight: 700!important;
  text-decoration: none!important;
  font-style: normal!important;
}

.activities figcaption {
  margin-bottom: 30px;
  border-radius: 10px;
}

.activities figure {
  position: relative;
}

.activities figcaption {
  opacity: 1;
  position: absolute;
  left: 30px;
  right: 30px;
  bottom: -45px;
  z-index: 10;
  transition: all ease-in-out 0.3s;
  /* background: rgba(255, 255, 255, 0.9); */
  padding: 5px 10px;
}

.activities .card-res + .col-lg-7 figcaption{
  background: #77be0c;
}

.span-res{
  color: #77be0c !important;
}
.span-mat{
  color: #83342c !important;
}
.activities .card-aid + .col-lg-6 figcaption{
  background: #3fa0cc;
}

.fig-mat{
  background: #83342c;
}

.activities figcaption h4 {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  /* color: #5f687b; */
  /* text-transform: uppercase; */
}

.activities figcaption p {
  color: #949cac;
  font-size: 14px;
  margin-bottom: 0;
}

.cta{
  /* height: 100px; */
  width: 100vw;
  background-size: cover;
  border: 1px solid #edeae4;
  background: #daefe6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cta .text-center{
  font-size: 22px;
}

.cta a{
  color: #023976;
  font-weight: 500;
}

.cta a:hover{
  color: #023976 !important;
}

.hover-underline{
  display: inline-block;
  position: relative;
  color: #0087ca;
}

.hover-underline:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #023976;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
} 

.hover-underline:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
} *

.cta-res{
  /* background: url('../../assets/img/res1.png') no-repeat center; */
  background-size: cover;
  margin-bottom: -5px;
}

/* 

.cta-res-bt{
  background: url('../../assets/img/wavebluebt.svg') no-repeat center;
  background-size: cover;
  margin-top: -5px;
}

.bg-res{
  background-color: #077dbc; 

}

.about .bg-res span{
  font-weight: bold;
  --bs-text-opacity: 1;
  color: #77be0c;
} */

.card-res, .card-aid, .card-mat{
  /* border: none !important; */
  /* background-color: transparent !important; */
  /* color: #fff !important; */
  border-radius: 10px;
}

.bord-circled{
  border-radius: 15px;
}

.section-header .header-main .btn, .navbar .btn {
  background-color: #fa9e6d !important;
  border-color: #fa9e6d !important;
  /* display: flex; */
  
}

/* .act-title{
  font-weight: 500;
  font-size: 3.46rem;
  font-weight: bold!important;
  line-height: 4.39rem;
  color: #000;
} */

.cards-wrapper{
  background: #f8fcf9;
}

.header-card{
  color: #231f20 !important;
  border-radius: 10px;
  height: 250px !important;
  width: 370px !important;
}

/* .dga{
  background: url(../../assets/img/inc.png);
  background-size: cover;
}

.dge{
  background: url(../../assets/img/eco.png);
  background-size: cover;
}

.dgi{
  background: url(../../assets/img/ess.png);
  background-size: cover;
} */

.cat-cont{
  padding: 0.85rem 1rem;
  position: relative;
  margin-bottom: -1px;
  background-color: rgba(240,241,240,.85);
  border-radius: 0 0 0.63rem 0.63rem;
  width: 110%;
  left: -17px;
  top: 185px;
}

.dgi .cat-cont h4{
  font-size: 1.47em ;
}

.dgi .cat-cont{
  height: 65px;
}

@media(max-width:720px) {
  .header-card{
    margin-top: 15px;
  }
}

.bg-sand{
  background-color: #f6f6f6;
  color: #6d6d6d;
}

.section-title{
  display: flex;
  justify-content: center;
}

.about h1.text-center{
  background: url("../../assets/img/lin.png") 50% 50% repeat-x;
  width: 80%;
}

.bg-wh{
  background-color: #f6f6f6;
  color: #231f20 !important;
}

.about .card p, .about .card li{
  font-size: 18px;
}

