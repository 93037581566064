/* desktop only */
/* desktop only .end */
/* ================ SECTION-FOOTER ==================  */
.section-footer ul li {
    margin-bottom: 5px;
  }

  .section-footer ul a {
    font-weight: 500;
    color: rgba(0,0,0,.7);
  }
  .section-footer ul a:hover {
    color: #666;
  }
  .section-footer .logo-footer {
    height: 40px;
  }
  .section-footer .title {
    font-weight: 600;
    margin-bottom: 16px;
    color: #212529;
  }
  
  .footer-bottom {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  .footer-copyright {
    padding-top: 20px;
    padding-bottom: 20px;
  }