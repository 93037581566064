@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

*{
  font-family: "Cabin", sans-serif;
}

h1,h2,h3 {
  margin-bottom: 1em;
  color: #2e2f5e;
  font-weight: 600;
  /* font-family: "Open Sans","Segoe UI",HelveticaNeue-Light,"Helvetica Neue Light","Helvetica Neue",Helvetica,Arial,sans-serif; */
  line-height: 1.428571429;
}

.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-loader {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* =================  Base =================== */

.main-content{
  min-height: 80vh;
}

a {
  text-decoration: none !important; 
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col, .form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-group {
  margin-bottom: 1.2rem;
}

/* =================  Background =================== */

.bg {
  background-color: #f8f9fa;
}

.bg-white {
  background-color: #fff;
}

.bg-orange {
  background-color: #ff9017;
}

.bg-green {
  background-color: #00b517;
}

.bg-blue {
  background-color: #3167eb;
}

.bg-red {
  background-color: #fa3434;
}

/* ================ Pages Header ================== */
.section-pagetop {
  padding: 25px 0;
}

/* =================  Icon =================== */

.icon-xs, .icon-sm, .icon-md, .icon-lg {
  display: inline-block;
  text-align: center;
  flex-shrink: 0;
  flex-grow: 0;
}

.icon-xs {
  width: 32px;
  height: 32px;
  line-height: 32px !important;
  font-size: 16px;
}

.icon-sm {
  width: 48px;
  height: 48px;
  line-height: 48px !important;
  font-size: 20px;
}

.icon-md {
  width: 60px;
  height: 60px;
  line-height: 60px !important;
  font-size: 28px;
}

.icon-lg {
  width: 96px;
  height: 96px;
  line-height: 96px !important;
  font-size: 42px;
}

.product .card-title, .cart h4{
  text-transform: capitalize;
}

.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}
.pagination > .active > a{
  background-color: #282c34 ;
  border-color: #282c34 ;
  color: #fff;
}
.pagination > li > a{
  border: 1px solid #282c34 ;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
  background-color: #47ccde ;
  border-color: #47ccde;
  outline: none ;
}
.pagination > li > a, .pagination > li > span{
  color: #282c34
}
.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
  border-radius: unset
}
.thumbnail{
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.35rem;
  max-width: 100%;
}

.price{
  font-size: large;
  font-weight: 700;
}

/* .btn{
  color: #fff !important;
} */

.btn.btn-primary{
  background-color: #fa9e6d;
  border-color: #fa9e6d;
  color: #fff;
}

.btn.btn-primary:hover {
  background-color: transparent !important;
  border-color: #fa9e6d !important;  
  color: #fa9e6d !important;
}

.btn-outline-primary{  
  background-color: transparent !important;
  border-color: rgb(41, 137, 143) !important;
  color: rgb(41, 137, 143) !important;
}

.btn-outline-primary:hover{
  border-color: rgb(41, 137, 143) !important;
  background-color: rgb(41, 137, 143) !important;
  color: #fff !important;
}

.card-subtitle {
  font-weight: 500;
}

.account .card-text {
  color: #9b9797;
}

.navbar {
  display: block !important;
  font-size: 1.1rem;
}

.navbar .btn {
  font-size: 1.1rem;
}

.navbar .nav-link, #input-group-dropdown-1{
  align-items: baseline;
}

.btn-hover:hover{
  background-color: #fa9e6d;
  color: #fff;
}